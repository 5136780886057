import styled from 'styled-components/macro';

const Wrapper = styled.div`
  .hero-container {
      width: 100%;
      height: 450px;
      background-color: grey;
      
  }
`;

export default Wrapper;
