import React from 'react';
import Homepage from './HomePage/container';

function App() {
  return (
    <div>
      <Homepage />
    </div>
  );
}

export default App;
